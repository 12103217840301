import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { motion, AnimatePresence } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import './FeatureL2.css';
import a from './cloud-done.png';
import b from './TI-DONE.png';
import c from './COLLEGE-DONE.png';
import d from './Disaster.png';
import e from './Managed.png';
import f from './Monitoring.png';
import g from './automation.png';
import h from './IT.png';
import i from './GA.PNG';

const features = [
  {
    icon: 'las la-search',
    background: "linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)",
    title: "תכנון ומחקר",
    description: "מחקרים וניתוח נתונים תוך תכנון מושכל ואסטרטגי להצלחה המרבית שלנו",
    delay: 0.2,
    path: "/Consulting"
  },
  {
    icon: 'las la-code',
    background: "linear-gradient(135deg, #FF6B6B 0%, #FF000F 100%)",
    title: "פיתוח",
    description: "אנחנו מתמחים בהפיכת הרעיונות שלכם למציאות באמצעות טכנולוגיות מתקדמות",
    delay: 0.3,
    path: "/Development"
  },
  {
    icon: 'las la-cogs',
    background: "linear-gradient(135deg, #36D1DC 0%, #5B86E5 100%)",
    title: "תפעול",
    description: "אנחנו מנהלים את התפעול ואת הצמיחה של הרעיון שלכם, דואגים למסע חלק וביצועים גבוהים",
    delay: 0.4,
    path: "/Operations"
  },
  {
    icon: 'las la-plug',
    background: "linear-gradient(135deg, #11998e 0%, #38ef7d 100%)",
    title: "אינטגרציות",
    description: "חיבור לפעולות שלך על ידי אינטגרציה חלקה של מערכות שונות, יוצרים תהליך עבודה יעיל ומאורגן",
    delay: 0.5,
    path: "/Operations"
  },
  {
    icon: 'las la-shield-alt',
    background: "linear-gradient(135deg, #8E2DE2 0%, #4A00E0 100%)",
    title: "אבטחה",
    description: "עם אבטחה בראש סדר העדיפויות שלנו, אנו מתכננים כל נקודת מגע עם שיקול דעת מירבי",
    delay: 0.6,
    path: "/Security"
  },
  {
    icon: 'las la-headset',
    background: "linear-gradient(135deg, #F2994A 0%, #F2C94C 100%)",
    title: "ייעוץ ותמיכה",
    description: "אנחנו משמשים כשותפי ייעוץ ותמיכה, מציעים פתרונות מומחים כשאתם צריכים",
    delay: 0.7,
    path: "/Consulting"
  }
];

function FeatureL2he() {
  const navigate = useNavigate();
  
  return (
    <div className="modern-feature-section rtl-section">
      {/* Platform Announcement */}
      <motion.div 
        className="platform-announcement"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Container>
          <div className="announcement-content">
            <div className="announcement-icon">
              <i className="las la-cloud"></i>
            </div>
            <div className="announcement-text">
              <motion.span
                className="new-badge"
                initial={{ scale: 1 }}
                animate={{ scale: [1, 1.2, 1] }}
                transition={{ duration: 2, repeat: Infinity, repeatDelay: 3 }}
              >
                חדש!
              </motion.span>
              <span className="announcement-message">
                שירותי ענן היברידי זמינים כעת בשני מתקנים באירופה
              </span>
            </div>
          </div>
        </Container>
      </motion.div>

      {/* Hero Section */}
      <motion.div 
        className="hero-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
                className="hero-content"
              >
                <motion.div
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3, duration: 0.6 }}
                  className="hero-badge"
                >
                  <span>🚀 מובילים חדשנות</span>
                </motion.div>
                
                <motion.h1 
                  className="hero-title"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 0.8 }}
                >
                  <span className="gradient-text" style={{
                    background: "linear-gradient(45deg, #4285F4, #34A853)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    display: "inline-block"
                  }}>חדשנות דיגיטלית</span>{' '}
                  <br />
                  <span>בעידן המודרני</span>
                </motion.h1>

                <motion.p 
                  className="hero-description"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.7, duration: 0.8 }}
                >
                  <span className="highlight-text">מובילים את המהפכה הטכנולוגית.</span>{' '}
                   אנחנו מקדמים יזמים, סטארטאפים ובעלי עסקים להצלחה בעולם הדיגיטלי המתפתח. 
                  <br /> <br />
                  <span className="emphasis-text">פתרונות  תוכנה מקצה לקצה</span>, 
                  {' '}
                  <span className="gradient-text" style={{background: "linear-gradient(135deg, #FF1493 0%, #9400D3 100%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent"}}>החזון שלך</span>- 
                  <span className="gradient-text" style={{background: "linear-gradient(135deg, #32CD32 0%, #008000 100%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent"}}>המומחיות שלנו</span> – 
                  <span className="bold-text">בוא נצמח יחד.</span>
                </motion.p>
                
                <motion.div 
                  className="hero-buttons"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Link to="https://boarding.epicfinal.com/login" className="primary-button">
                    <motion.span
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      className="button-content"
                    >
                      התחל עכשיו
                      <i className="las la-arrow-left"></i>
                    </motion.span>
                  </Link>
                  <Link to="https://meetings.hubspot.com/viacheslav-seifer" className="secondary-button">
                    <motion.span
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      className="button-content"
                    >
                      קבל יעוץ חינם
                      <i className="las la-info-circle"></i>
                    </motion.span>
                  </Link>
                  <Link 
                    to="/MARKETPLACE" 
                    state={{ language: 'he' }}
                    className="marketplace-button"
                  >
                    <motion.span
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      className="button-content"
                    >
                      <span>מרקטפלייס</span>
                      <i className="las la-store"></i>
                    </motion.span>
                  </Link>
                </motion.div>
              </motion.div>
            </Col>
            <Col lg={6}>
              <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
                className="hero-image-wrapper"
              >
                <motion.div
                  className="hero-image-container"
                  whileHover={{ scale: 1.02 }}
                  transition={{ duration: 0.3 }}
                >
                  <motion.div className="floating-elements">
                    <motion.div
                      className="float-element code"
                      animate={{ y: [-10, 10, -10] }}
                      transition={{ duration: 3, repeat: Infinity, ease: "easeInOut" }}
                    >
                      <i className="las la-code"></i>
                    </motion.div>
                    <motion.div
                      className="float-element cloud"
                      animate={{ y: [10, -10, 10] }}
                      transition={{ duration: 3.5, repeat: Infinity, ease: "easeInOut" }}
                    >
                      <i className="las la-cloud"></i>
                    </motion.div>
                    <motion.div
                      className="float-element rocket"
                      animate={{ y: [-15, 15, -15] }}
                      transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
                    >
                      <i className="las la-rocket"></i>
                    </motion.div>
                  </motion.div>
                  <div className="cicd-visualization-modern">
                    <motion.div 
                      className="pipeline-hexagon"
                      whileHover={{ scale: 1.1 }}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.2 }}
                    >
                      <div className="hexagon-content">
                        <i className="las la-code"></i>
                        <span>פיתוח</span>
                      </div>
                      <div className="connection-line"></div>
                    </motion.div>
                    
                    <motion.div 
                      className="pipeline-hexagon"
                      whileHover={{ scale: 1.1 }}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.4 }}
                    >
                      <div className="hexagon-content">
                        <i className="las la-vial"></i>
                        <span>בדיקות</span>
                      </div>
                      <div className="connection-line"></div>
                    </motion.div>
                    
                    <motion.div 
                      className="pipeline-hexagon"
                      whileHover={{ scale: 1.1 }}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.6 }}
                    >
                      <div className="hexagon-content">
                        <i className="las la-box"></i>
                        <span>בנייה</span>
                      </div>
                      <div className="connection-line"></div>
                    </motion.div>
                    
                    <motion.div 
                      className="pipeline-hexagon"
                      whileHover={{ scale: 1.1 }}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.8 }}
                    >
                      <div className="hexagon-content">
                        <i className="las la-rocket"></i>
                        <span>הטמעה</span>
                      </div>
                    </motion.div>
                    
                    <motion.div 
                      className="pipeline-progress"
                      initial={{ width: "0%" }}
                      animate={{ width: "100%" }}
                      transition={{ duration: 2, delay: 1, ease: "easeInOut" }}
                    />
                  </div>
                </motion.div>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </motion.div>

      {/* Features Grid */}
      <section className="features-section">
        <Container>
          <motion.div 
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="section-header text-center"
          >
            <h2 className="section-title">הפתרונות שלנו</h2>
            <p className="section-subtitle">גלה את מגוון השירותים המתקדמים שלנו</p>
          </motion.div>

          <div className="features-grid">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                className="modern-feature-card"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                whileHover={{ y: -10, transition: { duration: 0.2 } }}
                onClick={() => navigate(feature.path)}
              >
                <div className="feature-icon" style={{ background: feature.background }}>
                  <i className={feature.icon}></i>
                </div>
                <div className="feature-content">
                  <h3>{feature.title}</h3>
                  <p>{feature.description}</p>
                </div>
                <div className="feature-arrow">
                  <i className="las la-arrow-left"></i>
                </div>
              </motion.div>
            ))}
          </div>
        </Container>
      </section>

      {/* Platform Onboarding */}
      <section className="platform-onboarding-section">
        <Container>
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="platform-onboarding"
          >
            <h3 className="text-center">תהליך העבודה שלנו</h3>
            <div className="onboarding-steps">
              <div className="step">
                <div className="step-number">1</div>
                <div className="step-icon">🚀</div>
                <h4>צור פרויקט חדש</h4>
                <p>התחל מיד, בקלות ובמהירות</p>
              </div>
              
              <div className="step">
                <div className="step-number">2</div>
                <div className="step-icon">🎯</div>
                <h4>בחר את המסלול שלך ונתאם פגישה</h4>
                <p>קבל ייעוץ מומחה או התחל בהערכה חכמה</p>
              </div>                       
              
              <div className="step">
                <div className="step-number">3</div>
                <div className="step-icon">📊</div>
                <h4>עקוב אחר ההתקדמות</h4>
                <p>צפה בכל שלב דרך לוח הבקרה שלך</p>
              </div>
              
              <div className="step">
                <div className="step-number">4</div>
                <div className="step-icon">🎉</div>
                <h4>השלמת הפרויקט</h4>
                <p>קבל את קוד המקור או האישורים בקלות</p>
              </div>
            </div>
            
            <div className="platform-actions">
              <a href="https://boarding.epicfinal.com/register" 
                 className="platform-btn primary"
                 target="_blank"
                 rel="noopener noreferrer">
                <span className="button-text">התחל עכשיו בפלטפורמה</span>
                <i className="las la-arrow-left"></i>
              </a>
              <a href="https://meetings.hubspot.com/viacheslav-seifer" 
                 className="platform-btn green"
                 target="_blank"
                 rel="noopener noreferrer">
                <span className="button-text">קבע פגישה</span>
                <i className="las la-calendar"></i>
              </a>
            </div>
          </motion.div>
        </Container>
      </section>

      {/* Call to Action */}
      <motion.section
        className="cta-section"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
      >
        <Container>
          <div className="cta-content">
            <h2>מוכנים להתחיל?</h2>
            <p>צור קשר עוד היום ונתחיל לבנות את העתיד הדיגיטלי שלך</p>
            <div className="button-container">
              <a 
                href="https://boarding.epicfinal.com" 
                className="cta-button primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                התחל עכשיו
                <i className="las la-rocket ms-1"></i>
              </a>
              <a 
                href="https://meetings.hubspot.com/viacheslav-seifer" 
                className="cta-button secondary"
                target="_blank"
                rel="noopener noreferrer"
              >
                קבע פגישה
                <i className="las la-calendar ms-1"></i>
              </a>
            </div>
          </div>
        </Container>
      </motion.section>
    </div>
  );
}

export default FeatureL2he;
